export default {
  'dashboard.router.manager': 'Dashboard',
  'dashboard.summary.organizations': 'Tổ chức',
  'dashboard.summary.organizations.expired': 'Tổ chức hết hạn',
  'dashboard.summary.organizations.due': 'Tổ chức còn hạn',
  'dashboard.summary.capacityUsed.text': 'Dung lượng tổ chức đã sử dụng',
  'dashboard.summary.capacity.text': 'Dung lượng tổ chức đã đăng ký',
  'dashboard.filter.organizations': 'Tổ chức',
  'dashboard.filter.status': 'Trạng thái hoạt động',
  'dashboard.filter.status.active': 'Đang hoạt động',
  'dashboard.filter.status.notActive': 'Ngưng hoạt động',
  'dashboard.marker.device.name': 'Tên thiết bị',
  'dashboard.marker.organizations': 'Tổ chức',
  'dashboard.marker.specificAddress': 'Địa chỉ',
  'dashboard.marker.latitude': 'Vĩ độ',
  'dashboard.marker.longitude': 'Kinh độ',
  'dashboard.marker.status': 'Trạng thái',
  'dashboard.marker.device.info': 'Thông tin thiết bị',
  'dashboard.marker.devices.operationStatus.id':
    '{status,select, 1 {Đang hoạt động} 2 {Ngưng hoạt động} other {Không xác định}}',
  'dashboard.summary.facilities': 'Cơ sở phát',
  'dashboard.summary.totalDevice': 'Thiết bị phát bản tin',
  'dashboard.summary.totalNewsletter': 'Bản tin',
  'dashboard.summary.capacity': 'Dung lượng',
  'dashboard.summary.totalDevice.label': 'Tối đa',
  'dashboard.summary.totalDevice.valueDesc': 'thiết bị',

  // dashboard device
  'dashboard.device.title': 'Dashboard thiết bị',
  'dashboard.device.name': 'Thiết bị',
  'dashboard.device.facility.name': 'Cơ sở',
  'dashboard.device.deviceGroups': 'Nhóm thiết bị',

  'dashboard.device.connectionStatus': 'Trạng thái kết nối',
  'dashboard.device.connectionStatus.connecting': 'Đang kết nối',
  'dashboard.device.connectionStatus.notConnectedYet': 'Mất kết nối',

  'dashboard.device.playingStatus': 'Trạng thái phát bản tin',
  'dashboard.device.playingStatus.playing': 'Đang phát',
  'dashboard.device.playingStatus.notScheduledYet': 'Ngưng phát',

  'dashboard.device.operationStatus': 'Trạng thái hoạt động',
  'dashboard.device.operationStatus.active': 'Đang hoạt động',
  'dashboard.device.operationStatus.deactive': 'Ngưng hoạt động',

  'dashboard.device.information': 'Thông tin',

  'dashboard.device.filter.connectionStatus': 'Trạng thái kết nối',
  'dashboard.device.filter.connectionStatus.connecting': 'Đang kết nối',
  'dashboard.device.filter.connectionStatus.notConnectedYet': 'Mất kết nối',

  'dashboard.device.filter.playingStatus': 'Trạng thái phát bản tin',
  'dashboard.device.filter.playingStatus.playing': 'Đang phát',
  'dashboard.device.filter.playingStatus.notScheduledYet': 'Ngưng phát',

  'dashboard.device.filter.operationStatus': 'Trạng thái hoạt động',
  'dashboard.device.filter.operationStatus.active': 'Đang hoạt động',
  'dashboard.device.filter.operationStatus.deactive': 'Ngưng hoạt động',

  'dashboard.device.chart.title.connectionStatus': 'Tỷ lệ kết nối của thiết bị',
  'dashboard.device.chart.title.playingStatus': 'Tỷ lệ phát bản tin của thiết bị',
  'dashboard.device.chart.title.operationStatus': 'Tỷ lệ hoạt động của thiết bị',

  'dashboard.device.chart.title': 'Danh sách thiết bị',
  'dashboard.device.export': 'Xuất file excel',

  'dashboard.marker.device.deviceGroup': 'Nhóm thiết bị',
};
